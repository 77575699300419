import React from 'react';
import { IEveQuickOrderResources } from '../eve-quick-order.props.autogenerated';

interface IEveQuickOrderControls {
    canAddToCart: number;
    resources: IEveQuickOrderResources;
    isLavidgeMobile?: boolean;
    handleAddRow: () => void;
    handleAddToCart: () => void;
}

export const EveQuickOrderControls: React.FC<IEveQuickOrderControls> = props => {
    const baseProps = { 'data-ignore-parent-css': true };
    const { handleAddRow, resources, handleAddToCart, isLavidgeMobile } = props;
    const { addToCart, addRow } = resources;
    const _controlsClassname = `cps-quick-order__controls`;

    if (isLavidgeMobile) {
        return (
            <tfoot {...baseProps} className={_controlsClassname}>
                <tr {...baseProps}>
                    <th {...baseProps} colSpan={3}>
                        <div {...baseProps} className={`${_controlsClassname}--main-group`}>
                            <button {...baseProps} className={`${_controlsClassname}--add-row`} onClick={handleAddRow}>
                                {addRow}
                            </button>
                            <div {...baseProps} className={`${_controlsClassname}--secondary-group`}>
                                <button {...baseProps} className={`${_controlsClassname}--add-to-cart`} onClick={handleAddToCart}>
                                    {addToCart}
                                </button>
                            </div>
                        </div>
                    </th>
                </tr>
            </tfoot>
        );
    }

    return (
        <div {...baseProps} className={_controlsClassname}>
            <div {...baseProps} className={`${_controlsClassname}--main-group`}>
                <button {...baseProps} className={`${_controlsClassname}--add-row`} onClick={handleAddRow}>
                    {addRow}
                </button>
                <div {...baseProps} className={`${_controlsClassname}--secondary-group`}>
                    <button {...baseProps} className={`${_controlsClassname}--add-to-cart`} onClick={handleAddToCart}>
                        {addToCart}
                    </button>
                </div>
            </div>
        </div>
    );
};
