import React from 'react';
import { EveQuickOrderItemsInput, IEveQuickOrderItemsData } from '../../../actions/eve-quick-order-items.action';
import { IEveQuickOrderData } from '../eve-quick-order.data';
import { IEveQuickOrderProps } from '../eve-quick-order.props.autogenerated';
import { searchByTextAsync, searchAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { AsyncResult, ProductVariant } from '@msdyn365-commerce/retail-proxy';
import { debounce } from 'lodash';

declare global {
    interface Window {
        debouncer: NodeJS.Timeout;
        tempItemNumber: string;
    }
}

interface IEveQuickOrderRowProps {
    context: IEveQuickOrderProps<IEveQuickOrderData>['context'];
    allRows: IEveQuickOrderItemsData['items'];
    initialValues: IEveQuickOrderItemsData['items'][0];
    resources: IEveQuickOrderProps<IEveQuickOrderData>['resources'];
    rowIndex: number;
    isLavidgeMobile?: boolean;
    removeRow: () => void;
}

interface IEveQuickOrderCell {
    id: string;
    label: string;
    type: 'text' | 'number';
    value: string | number;
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

export const EveQuickOrderRow: React.FC<IEveQuickOrderRowProps & IEveQuickOrderProps<IEveQuickOrderData>> = props => {
    const baseProps = { 'data-ignore-parent-css': true };
    const idPrefixes = { itemNumber: 'item-number--', configuration: 'configuration--', quantity: 'quantity--' };
    const { context, rowIndex, removeRow, initialValues, resources, allRows, config, isLavidgeMobile } = props;
    const { minQueryLength = 1, maxQuantity = 0 } = config;
    const { actionContext, request } = context;
    const { channelId, catalogId } = request.apiSettings;
    const { discount, variants, selectedVariant, description } = initialValues;

    const [showSearchedItems, setShowSearchedItems] = React.useState(false);
    const [itemNumber, setItemNumber] = React.useState(initialValues.itemNumber);
    const [configuration] = React.useState(getConfigurationString(selectedVariant));
    const [quantity, setQuantity] = React.useState(initialValues.quantity);
    const [showDiscountTooltip, setShowDiscountTooltip] = React.useState(false);
    const [showDescriptionTooltip, setShowDescriptionTooltip] = React.useState(false);
    const [searchItems, setSearchItems] = React.useState<({ [key in 'itemNumber' | 'productName']: string } & { recordID: number })[]>([]);
    const [shouldHandleItemNumberChange, setShouldHandleItemNumberChange] = React.useState(true);
    const [isLoadingConfigurations, setIsLoadingConfiguration] = React.useState(false);
    const [isTextSearching, setIsTextSearching] = React.useState(false);

    const itemNumberInputRef = React.useRef<HTMLInputElement>(null);

    const cn = (suffix: string) => `cps-quick-order__table-body${suffix}`;

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        type: 'item-number' | 'configuration' | 'quantity',
        elementID: string,
        timeout?: number
    ) => {
        const { value } = event.currentTarget;
        const items = allRows.slice();
        const variant = variants?.filter(({ VariantId }) => VariantId === value)[0];
        if (type === 'configuration') {
            items[`${rowIndex}`].selectedVariantID = variant?.VariantId;
            items[`${rowIndex}`].selectedVariant = variant;
            actionContext.update<IEveQuickOrderItemsData>(new EveQuickOrderItemsInput(), { items });
        }
        if (type === 'quantity') {
            const qty = parseInt(value, 10);
            items[`${rowIndex}`].quantity = maxQuantity > 0 && qty > maxQuantity ? maxQuantity : qty;
            actionContext.update<IEveQuickOrderItemsData>(new EveQuickOrderItemsInput(), { items });
            setQuantity(qty);
        }
        if (type === 'item-number') {
            setItemNumber(value);
            window.tempItemNumber = event.target.value;
            debounce(() => {
                handleItemNumberChange(window.tempItemNumber);
                document.querySelector<HTMLInputElement>(`#${elementID}`)?.focus();
            }, timeout ?? 0)();
            setShouldHandleItemNumberChange(true);
            if (items[`${rowIndex}`].selectedVariantID?.length) {
                delete items[`${rowIndex}`].description;
                delete items[`${rowIndex}`].discount;
                delete items[`${rowIndex}`].selectedVariant;
                delete items[`${rowIndex}`].selectedVariantID;
                delete items[`${rowIndex}`].variants;
                items[`${rowIndex}`].quantity = 0;
                actionContext.update<IEveQuickOrderItemsData>(new EveQuickOrderItemsInput(), { items });
            }
        }
    };

    const handleFocusItemNumberInput = () => {
        itemNumberInputRef.current?.classList.remove(cn('--cell-input-muted'));
        setShowSearchedItems(true);
    };

    const handleBlurItemNumberInput = () => {
        const { value } = itemNumberInputRef.current ?? {};
        if ((value?.length ?? 0) > 0) {
            itemNumberInputRef.current?.classList.add(cn('--cell-input-muted'));
        }
        setTimeout(() => {
            setShowSearchedItems(false);
        }, 200);
    };

    const handleItemNumberChange = (itemNumber: string) => {
        if (shouldHandleItemNumberChange && itemNumber.length >= minQueryLength) {
            setIsTextSearching(true);
            const callerContext = { callerContext: actionContext, queryResultSettings: { Paging: { Top: 5 } } };
            setSearchItems([]);
            searchByTextAsync(callerContext, channelId, catalogId, itemNumber)
                .then(results => {
                    results.forEach(r => console.log(r));
                    setSearchItems(
                        results.map(({ ProductNumber, Name, RecordId }) => ({
                            recordID: RecordId,
                            itemNumber: ProductNumber ?? '',
                            productName: Name ?? ''
                        }))
                    );
                    setShowSearchedItems(true);
                    setIsTextSearching(false);
                })
                .catch(() => {});
        }
    };

    const handleSelectSearchItem = (itemNumber: string, recordID: number) => {
        setIsLoadingConfiguration(true);
        setItemNumber(itemNumber);
        setSearchItems([]);
        setShouldHandleItemNumberChange(false);

        const orderItems = actionContext.get('EVE-QUICK-ORDER-ITEMS', 'EVE-QUICK-ORDER-ITEMS') as AsyncResult<IEveQuickOrderItemsData>;
        const items = (orderItems.result ?? { items: [] }).items.slice();
        items[`${rowIndex}`].itemNumber = itemNumber;

        searchAsync(
            { callerContext: actionContext, queryResultSettings: { Paging: { Top: 1 } } },
            {
                Ids: [recordID],
                Context: { CatalogId: catalogId, ChannelId: channelId }
            }
        )
            .then(result => {
                result.forEach(r => console.log(r));
                const [{ CompositionInformation, ProductName, Description }] = result;
                const { VariantInformation } = CompositionInformation ?? {};
                const { Variants = [] } = VariantInformation ?? {};
                items[`${rowIndex}`].variants = Variants;
                items[`${rowIndex}`].selectedVariantID = Variants[0].VariantId;
                items[`${rowIndex}`].selectedVariant = Variants[0];
                items[`${rowIndex}`].description = { name: ProductName, text: Description };
                actionContext.update<IEveQuickOrderItemsData>(new EveQuickOrderItemsInput(), { items });
            })
            .catch(() => {});
    };

    function getConfigurationString(variant?: ProductVariant) {
        return variant
            ? `${variant.ConfigId?.length && variant.ConfigId !== '.' ? variant.ConfigId : ''}(Qty)${
                  variant.ColorId?.length && variant.ColorId !== '.' ? `/${variant.ColorId}` : ''
              } - $Price ($${variant.Price})`
            : '';
    }

    function isDescription(data: typeof description | typeof discount): data is typeof description {
        return data?.['name'] || data?.['text'];
    }

    const cells: IEveQuickOrderCell[] = [
        {
            id: `${idPrefixes.itemNumber}${rowIndex}`,
            label: resources.tableHeaderItemNumber,
            type: 'text',
            value: itemNumber,
            onChange: event => handleChange(event, 'item-number', `${idPrefixes.itemNumber}${rowIndex}`, 300)
        },
        {
            id: `${idPrefixes.configuration}${rowIndex}`,
            label: resources.tableHeaderConfiguration,
            type: 'text',
            value: configuration,
            onChange: event => handleChange(event, 'configuration', `${idPrefixes.configuration}${rowIndex}`)
        },
        {
            id: `${idPrefixes.quantity}${rowIndex}`,
            label: resources.tableHeaderQuantity,
            type: 'number',
            value: quantity,
            onChange: event => handleChange(event, 'quantity', `${idPrefixes.quantity}${rowIndex}`, 300)
        }
    ];

    const baseTooltipHandler = (setter: (value: React.SetStateAction<boolean>) => void, show: boolean) => () => {
        clearTimeout(window.debouncer);
        if (innerWidth > 992) {
            window.debouncer = setTimeout(() => setter(show), show ? 200 : 0);
        }
    };

    const discountTooltipHandlers = {
        onMouseEnter: baseTooltipHandler(setShowDiscountTooltip, true),
        onMouseMove: baseTooltipHandler(setShowDiscountTooltip, true),
        onMouseLeave: baseTooltipHandler(setShowDiscountTooltip, false)
    };

    const descriptionTooltipHandlers = {
        onMouseEnter: baseTooltipHandler(setShowDescriptionTooltip, true),
        onMouseMove: baseTooltipHandler(setShowDescriptionTooltip, true),
        onMouseLeave: baseTooltipHandler(setShowDescriptionTooltip, false)
    };

    const tooltips = [
        {
            index: 0,
            show: showDescriptionTooltip,
            content: description,
            setter: setShowDescriptionTooltip,
            handlers: descriptionTooltipHandlers,
            svg: (
                <svg {...descriptionTooltipHandlers} fill='white' viewBox='0 0 192 512' {...baseProps}>
                    <path
                        d='M20 424.2h20V279.8H20c-11 0-20-9-20-20V212c0-11 9-20 20-20h112c11 0 20 9 20 20v212.2h20c11 0 20 9 20 20V492c0 11-9 20-20 20H20c-11 0-20-9-20-20v-47.8c0-11 9-20 20-20zM96 0C56.2 0 24 32.2 24 72s32.2 72 72 72 72-32.2 72-72S135.8 0 96 0z'
                        {...baseProps}
                    />
                </svg>
            )
        },
        {
            index: 2,
            show: showDiscountTooltip,
            content: discount,
            setter: setShowDiscountTooltip,
            handlers: discountTooltipHandlers,
            svg: (
                <svg
                    {...discountTooltipHandlers}
                    fill='white'
                    viewBox='0 0 288 512'
                    style={{ height: '13px', transform: 'translate(0.6px, 0.5px)' }}
                    {...baseProps}
                >
                    <path
                        d='M209.2 233.4l-108-31.6C88.7 198.2 80 186.5 80 173.5c0-16.3 13.2-29.5 29.5-29.5h66.3c12.2 0 24.2 3.7 34.2 10.5 6.1 4.1 14.3 3.1 19.5-2l34.8-34c7.1-6.9 6.1-18.4-1.8-24.5C238 74.8 207.4 64.1 176 64V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-2.5C45.8 64-5.4 118.7 .5 183.6c4.2 46.1 39.4 83.6 83.8 96.6l102.5 30c12.5 3.7 21.2 15.3 21.2 28.3 0 16.3-13.2 29.5-29.5 29.5h-66.3C100 368 88 364.3 78 357.5c-6.1-4.1-14.3-3.1-19.5 2l-34.8 34c-7.1 6.9-6.1 18.4 1.8 24.5 24.5 19.2 55.1 29.9 86.5 30v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48.2c46.6-.9 90.3-28.6 105.7-72.7 21.5-61.6-14.6-124.8-72.5-141.7z'
                        {...baseProps}
                    />
                </svg>
            )
        }
    ];

    if (isLavidgeMobile) {
        return (
            <tr {...baseProps}>
                <td {...baseProps}>
                    <div className={cn(`--cell-input-wrapper`)} {...baseProps}>
                        <input
                            className={`${cn(`--cell-input`)}${cells[0].value.toString().length > 0 ? ` ${cn('--cell-input-muted')}` : ''}`}
                            ref={itemNumberInputRef}
                            id={cells[0].id}
                            placeholder={minQueryLength > 0 ? `Enter ${minQueryLength} characters...` : ''}
                            onChange={cells[0].onChange}
                            onFocus={handleFocusItemNumberInput}
                            onBlur={handleBlurItemNumberInput}
                            type={cells[0].type}
                            value={cells[0].value}
                            min={0}
                            {...baseProps}
                        />
                        {isLoadingConfigurations ? <div id={cells[1].id} className={cn('--cell-select-loading')} {...baseProps} /> : null}
                        {(variants?.length ?? 0) > 1 && !isLoadingConfigurations ? (
                            <select onChange={cells[0].onChange} id={cells[0].id} className={cn('--cell-select')} {...baseProps}>
                                {variants?.map(variant => (
                                    <option key={variant.VariantId} value={variant.VariantId} {...baseProps}>
                                        {getConfigurationString(variant)}
                                    </option>
                                ))}
                            </select>
                        ) : null}
                        {variants?.length === 1 && !isLoadingConfigurations ? (
                            <div id={cells[0].id} className={cn('--cell-select-no-option')} {...baseProps}>
                                {getConfigurationString(variants[0])}
                            </div>
                        ) : null}
                    </div>
                </td>
                <td {...baseProps}>
                    {variants?.length ? (
                        <div className={cn(`--cell-input-quantity-wrapper`)} {...baseProps}>
                            <input
                                className={`${cn(`--cell-input`)}${discount?.length ? ` ${cn('--cell-input-with-tooltip')}` : ''}`}
                                id={cells[2].id}
                                onChange={cells[2].onChange}
                                type={cells[2].type}
                                value={cells[2].value}
                                min={0}
                                {...baseProps}
                            />
                        </div>
                    ) : null}
                </td>
                <td className={cn('--cell')} {...baseProps}>
                    <button className={cn('--delete-row-button')} onClick={removeRow} {...baseProps}>
                        <svg viewBox='0 0 352 512' {...baseProps}>
                            <path
                                d='M242.7 256l100.1-100.1c12.3-12.3 12.3-32.2 0-44.5l-22.2-22.2c-12.3-12.3-32.2-12.3-44.5 0L176 189.3 75.9 89.2c-12.3-12.3-32.2-12.3-44.5 0L9.2 111.5c-12.3 12.3-12.3 32.2 0 44.5L109.3 256 9.2 356.1c-12.3 12.3-12.3 32.2 0 44.5l22.2 22.2c12.3 12.3 32.2 12.3 44.5 0L176 322.7l100.1 100.1c12.3 12.3 32.2 12.3 44.5 0l22.2-22.2c12.3-12.3 12.3-32.2 0-44.5L242.7 256z'
                                {...baseProps}
                            />
                        </svg>
                    </button>
                </td>
                {searchItems.length ? (
                    <div className={`${cn('--search-item-wrapper')}${showSearchedItems ? ' show' : ''}`} {...baseProps}>
                        {searchItems.map(({ recordID, itemNumber, productName }, i) => (
                            <button
                                className={cn('--search-item')}
                                key={productName.length ? productName : i}
                                style={{ width: 'px' }}
                                onClick={() => handleSelectSearchItem(itemNumber, recordID)}
                                {...baseProps}
                            >
                                <span {...baseProps}>
                                    {itemNumber} - {productName}
                                </span>
                            </button>
                        ))}
                    </div>
                ) : null}
            </tr>
        );
    }

    return (
        <div className={cn('--row')} {...baseProps}>
            {cells.map(({ type, onChange, id, value, label }, i) => (
                <div key={id} className={`${cn('--cell')} ${cn('--cell-input-group')}`} {...baseProps}>
                    <label htmlFor={id} {...baseProps}>
                        {label.toUpperCase()}
                    </label>
                    {i === 1 && (variants?.length ?? 0) > 1 && !isLoadingConfigurations ? (
                        <select onChange={onChange} id={id} className={cn('--cell-select')} {...baseProps}>
                            {variants?.map(variant => (
                                <option key={variant.VariantId} value={variant.VariantId} {...baseProps}>
                                    {getConfigurationString(variant)}
                                </option>
                            ))}
                        </select>
                    ) : null}
                    {i === 1 && variants?.length === 1 && !isLoadingConfigurations ? (
                        <div id={id} className={cn('--cell-select-no-option')} {...baseProps}>
                            {getConfigurationString(variants[0])}
                        </div>
                    ) : null}
                    {i === 1 && isLoadingConfigurations ? <div id={id} className={cn('--cell-select-loading')} {...baseProps} /> : null}
                    {i === 0 || (i === 2 && (variants?.length || (!variants?.length && isLoadingConfigurations))) ? (
                        <div className={cn(`--cell-input${i === 2 ? '-quantity' : ''}-wrapper`)} {...baseProps}>
                            <input
                                className={`${cn(`--cell-input`)}${
                                    i === 2 && discount?.length ? ` ${cn('--cell-input-with-tooltip')}` : ''
                                }${i === 0 && value.toString().length > 0 ? ` ${cn('--cell-input-muted')}` : ''}`}
                                ref={i === 0 ? itemNumberInputRef : undefined}
                                id={id}
                                placeholder={minQueryLength > 0 ? `Enter ${minQueryLength} characters...` : ''}
                                onChange={onChange}
                                onFocus={i === 0 ? handleFocusItemNumberInput : undefined}
                                onBlur={i === 0 ? handleBlurItemNumberInput : undefined}
                                type={type}
                                value={value}
                                min={0}
                                {...baseProps}
                            />
                            {tooltips.map(({ content, show, setter, handlers, index, svg }) =>
                                i === index && content ? (
                                    <>
                                        <button
                                            {...handlers}
                                            tabIndex={-1}
                                            className={cn('--cell-input-tooltip-trigger')}
                                            onClick={() => (innerWidth <= 992 ? setter(state => !state) : null)}
                                            onBlur={() => (innerWidth <= 992 ? setter(false) : null)}
                                            {...baseProps}
                                        >
                                            {svg}
                                        </button>
                                        <div
                                            className={`${cn('--cell-input-tooltip-content')}${
                                                show && i === 0 && innerWidth > 992
                                                    ? ' show-from-right'
                                                    : show && ((i === 0 && innerWidth <= 992) || i === 2)
                                                    ? ' show-from-left'
                                                    : ''
                                            }`}
                                            {...baseProps}
                                        >
                                            {!isDescription(content) ? content.map(() => '') : null}
                                            {isDescription(content) ? <>{content.name}</> : null}
                                        </div>
                                    </>
                                ) : null
                            )}
                        </div>
                    ) : null}
                    {i === 0 &&
                    !searchItems.length &&
                    !variants?.length &&
                    !isTextSearching &&
                    (document.querySelector<HTMLInputElement>(`#${idPrefixes.itemNumber}${rowIndex}`)?.value.length ?? 0) >=
                        minQueryLength ? (
                        <div className={`${cn('--search-item-wrapper')}${showSearchedItems ? ' show' : ''}`} {...baseProps}>
                            <div className={cn('--search-item-not-found')} {...baseProps}>
                                No products found
                            </div>
                        </div>
                    ) : null}
                    {i === 0 && searchItems.length ? (
                        <div className={`${cn('--search-item-wrapper')}${showSearchedItems ? ' show' : ''}`} {...baseProps}>
                            {searchItems.map(({ recordID, itemNumber, productName }, i) => (
                                <button
                                    className={cn('--search-item')}
                                    key={productName.length ? productName : i}
                                    onClick={() => handleSelectSearchItem(itemNumber, recordID)}
                                    {...baseProps}
                                >
                                    {itemNumber} - {productName}
                                </button>
                            ))}
                        </div>
                    ) : null}
                </div>
            ))}
            <div className={cn('--cell')} {...baseProps}>
                <button className={cn('--delete-row-button')} onClick={removeRow} {...baseProps}>
                    <svg viewBox='0 0 352 512' {...baseProps}>
                        <path
                            d='M242.7 256l100.1-100.1c12.3-12.3 12.3-32.2 0-44.5l-22.2-22.2c-12.3-12.3-32.2-12.3-44.5 0L176 189.3 75.9 89.2c-12.3-12.3-32.2-12.3-44.5 0L9.2 111.5c-12.3 12.3-12.3 32.2 0 44.5L109.3 256 9.2 356.1c-12.3 12.3-12.3 32.2 0 44.5l22.2 22.2c12.3 12.3 32.2 12.3 44.5 0L176 322.7l100.1 100.1c12.3 12.3 32.2 12.3 44.5 0l22.2-22.2c12.3-12.3 12.3-32.2 0-44.5L242.7 256z'
                            {...baseProps}
                        />
                    </svg>
                </button>
            </div>
        </div>
    );
};
